import { ReactNode } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { ERPs } from '@global-interfaces/enums';

const ERPRow = ({
  erp,
  handleSelectERP,
  handleConnect,
  isConnect,
  buttonDisabled,
  erpIsSynced,
  buttonText,
  children
}: {
  erp: {
    icon: string | undefined;
    erp: ERPs;
    name: string;
    text: string | JSX.Element;
    buttonText?: string;
  };
  handleSelectERP?: (erp: ERPs) => void;
  handleConnect?: (erp: ERPs) => void;
  isConnect?: boolean;
  buttonDisabled?: boolean;
  erpIsSynced?: boolean;
  buttonText?: string;
  children?: ReactNode;
}) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2} sx={{ p: 4 }}>
      <Stack direction="column" alignItems="center" sx={{ width: '130px' }}>
        <img src={erp.icon} alt={`${erp.name} app logo`} style={{ height: 24, width: 24 }} />
        <span>{erp.name}</span>
      </Stack>
      <div>{erp.text}</div>
      {erpIsSynced ? (
        <span className="erp__synced-badge">Synced</span>
      ) : isConnect ? (
        <Button variant="contained" onClick={() => handleConnect?.(erp.erp)} disabled={buttonDisabled}>
          {buttonText}
        </Button>
      ) : (
        <button
          className="btn btn-primary btn-blue"
          onClick={() => handleSelectERP?.(erp.erp)}
          disabled={buttonDisabled}>
          Select
        </button>
      )}
      {children}
    </Stack>
  );
};

export default ERPRow;
