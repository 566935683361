import qs from 'qs';
import api from './config';
import Project, { ProjectSettings } from '@global-interfaces/Project';
import Vendor from '@global-interfaces/Vendor';
import Address from '@global-interfaces/Address';

export interface ProjectToSendValues {
  name?: string;
  billing_due_date?: number;
  billing_frequency?: string;
  active?: boolean;
  tax_rate?: number;
  address?: {
    address_1?: string;
    city?: string;
    zip_code?: string;
    state?: string;
  };
  owner?: string;
  sage_id?: string;
  expense_account?: string;
  project_settings?: ProjectSettings;
}

interface VendorForm extends Omit<Vendor, 'address'> {
  address: Partial<Address>;
}

const companiesAPI = {
  integrations: {
    update: async (id: number, data: { sage_host: string }) => api.put(`/integrations/${id}/`, data).then((res) => res)
  },
  vendor: {
    list: async (
      page: number,
      params: {
        columnFilters?: unknown[];
        page?: number;
        page_size?: number;
        q?: string;
        sort?: string;
      }
    ) =>
      api.get('v4/vendors/', {
        params: { ...params, page: page ? page : 1 },
        paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
      }),
    bank: {
      fetch: async (vendorId: number) => api.get(`v4/vendors/${vendorId}/bank/`),
      // biome-ignore lint/suspicious/noExplicitAny: safe to ignore
      create: async (vendorId: number, data: any) => api.post(`v4/vendors/${vendorId}/bank/`, data),
      // biome-ignore lint/suspicious/noExplicitAny: safe to ignore
      update: async (vendorId: number, data: any) => api.put(`v4/vendors/${vendorId}/bank/`, data)
    },
    create: async (data: Partial<VendorForm>) => api.post<Vendor>('vendors/', data),
    fetch: async (id: number) => api.get<Vendor>(`vendors/${id}/`).then((res) => res),
    fetchBulk: async (ids: number[]) => api.get<Vendor[]>(`vendors?ids=${ids}`, { params: { page_size: 0 } }),
    update: async (id: number, data: Partial<VendorForm>) => api.patch(`vendors/${id}/`, data),
    // biome-ignore lint/suspicious/noExplicitAny: safe to ignore
    autocomplete: async (page: number, params: any) =>
      api.get('vendors/autocomplete/', {
        params: { ...params, page: page ? page : 1 },
        paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) }
      }),
    // biome-ignore lint/suspicious/noExplicitAny: safe to ignore
    invite: async (id: number, data: any) => api.post(`vendors/${id}/invite/`, data),
    // biome-ignore lint/suspicious/noExplicitAny: safe to ignore
    registration: async (token: string, data: any) => api.post(`registration/${token}/vendor/`, data)
  },
  project: {
    create: async (data: { name: string; company_id: number; users?: number[] }) =>
      api.post<Project>('projects/', data),
    fetch: async (id: number) => api.get(`projects/${id}/`),
    update: async (id: number, data: ProjectToSendValues) => api.patch(`projects/${id}/`, data)
  },
  address: {
    fetch: async (id: number) => api.get<Address>(`addresses/${id}/`),
    create: async (data: Partial<Address>) => api.post<Address>('addresses/', data),
    update: async (id: number, data: Partial<Address>) => api.patch<Address>(`addresses/${id}/`, data)
  }
};

export default companiesAPI;
