import { useState, useCallback, useMemo, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';

import Store from '@mui/icons-material/Store';
import Person from '@mui/icons-material/Person';
import Logout from '@mui/icons-material/Logout';
import Settings from '@mui/icons-material/Settings';
import HandshakeIcon from '@mui/icons-material/Handshake';
import EngineeringIcon from '@mui/icons-material/Engineering';

import { useToast } from '@global-contexts/toast';
import { useAuth } from '@global-contexts/auth';
import { useLoading } from '@global-contexts/loading';

const stringAvatar = (name: string) => {
  const [firstName, secondName] = name.split(' ') ?? ['', ''];

  return {
    sx: {
      width: 32,
      height: 32
    },
    children: (
      <span style={{ fontSize: '14px' }}>{`${firstName[0].toUpperCase()}${
        secondName?.[0].toUpperCase?.() ?? ''
      }`}</span>
    )
  };
};

const ProfileMenu = () => {
  const navigate = useNavigate();

  const { toast } = useToast();
  const { showBackdrop, hideBackdrop } = useLoading();
  const { user, company: userCompany, switchCompany } = useAuth();

  const alternativeCompanies = useMemo(() => {
    const foundCompanies = user?.profile?.alternative_companies ?? [];

    return foundCompanies.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));
  }, [user?.profile?.alternative_companies]);

  const [anchorMenuEl, setAnchorMenuEl] = useState<HTMLElement | null>(null);
  const menuOpen = Boolean(anchorMenuEl);

  const [companiesAnchorEl, setCompaniesAnchorEl] = useState<HTMLElement | null>(null);
  const menuCompaniesOpen = Boolean(companiesAnchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorMenuEl(event.currentTarget as HTMLElement);
  };

  const handleClose = () => {
    setAnchorMenuEl(null);
    setCompaniesAnchorEl(null);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: safe to ignore
  const onRedirect = useCallback(
    (to: string) => {
      if (menuCompaniesOpen) {
        setCompaniesAnchorEl(null);
      }

      setAnchorMenuEl(null);

      navigate(to);
    },
    [history, menuOpen, menuCompaniesOpen]
  );

  if (!user) {
    return null;
  }

  return (
    <>
      <Button
        id="fade-button"
        aria-controls={menuOpen ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={handleClick}
        sx={{ bgcolor: '#fafbfd', height: '48px', boxShadow: '-1px -1px 0px 0px #f1f3fa', borderRadius: 'initial' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', maxWidth: '230px' }}>
          <Avatar
            {...stringAvatar(userCompany.name ?? '')}
            src={userCompany.logo}
            imgProps={{ sx: { objectFit: 'contain' } }}
          />

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', overflow: 'hidden' }}>
            <Typography
              variant="body2"
              color="primary"
              fontSize="14px"
              fontWeight="500"
              lineHeight="14px"
              letterSpacing="normal"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '100%',
                textAlign: 'left'
              }}>
              {user.full_name}
            </Typography>
            <Typography
              variant="body2"
              color="primary"
              fontSize="12px"
              fontWeight="400"
              letterSpacing="normal"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '100%',
                textAlign: 'left'
              }}>
              {user?.profile?.company?.name}
            </Typography>
          </Box>
        </Box>
      </Button>

      <Menu
        disableScrollLock
        anchorEl={anchorMenuEl}
        open={menuOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <MenuItem onClick={() => onRedirect('/settings/profile')} data-testid="profile-menu-my-account">
          <ListItemIcon>
            <Person fontSize="small" />
          </ListItemIcon>
          <Typography variant="body2" fontSize="14px" color="#6c757d">
            My Account
          </Typography>
        </MenuItem>

        {user.isAdmin ? (
          <MenuItem onClick={() => onRedirect('/settings/general')} data-testid="profile-menu-setting">
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            <Typography variant="body2" fontSize="14px" color="#6c757d">
              Settings
            </Typography>
          </MenuItem>
        ) : (
          [
            <MenuItem key="vendors" onClick={() => onRedirect('/settings/vendors')} data-testid="profile-menu-vendors">
              <ListItemIcon>
                <HandshakeIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="body2" fontSize="14px" color="#6c757d">
                Vendors
              </Typography>
            </MenuItem>,
            <MenuItem
              key="projects"
              onClick={() => onRedirect('/settings/projects')}
              data-testid="profile-menu-projects">
              <ListItemIcon>
                <EngineeringIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="body2" fontSize="14px" color="#6c757d">
                Projects
              </Typography>
            </MenuItem>
          ]
        )}

        {alternativeCompanies.length > 0 && (
          <MenuItem
            onClick={(event) => setCompaniesAnchorEl(event.currentTarget as HTMLElement)}
            data-testid="profile-companies-setting">
            <ListItemIcon>
              <Store fontSize="small" />
            </ListItemIcon>
            <Typography variant="body2" fontSize="14px" color="#6c757d">
              My Companies
            </Typography>
          </MenuItem>
        )}

        <Divider />

        <MenuItem onClick={() => onRedirect('/logout')} data-testid="profile-menu-logout">
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <Typography variant="body2" fontSize="14px" color="#6c757d">
            Logout
          </Typography>
        </MenuItem>
      </Menu>

      <Menu
        disableScrollLock
        anchorEl={companiesAnchorEl}
        open={menuCompaniesOpen}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxWidth: 300
          }
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        {alternativeCompanies.map((company, i) => (
          <MenuItem
            key={company.id}
            divider={i !== alternativeCompanies.length - 1}
            onClick={async () => {
              showBackdrop();

              await switchCompany(company.id)
                .then(() => {
                  navigate('/');
                  window.location.reload();
                })
                .catch(() => {
                  toast('Something went wrong while switching company', { type: 'error' });
                  hideBackdrop();
                });
            }}>
            <ListItemIcon>
              <Avatar
                alt="user-avatar"
                {...stringAvatar(company.name)}
                src={company.logo}
                imgProps={{ sx: { objectFit: 'contain' } }}
              />
            </ListItemIcon>
            <Typography variant="body2" fontSize="14px" color="#6c757d" noWrap>
              {company.name}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ProfileMenu;
