import { useQuery, useMutation } from '@tanstack/react-query';
import companiesAPI, { ProjectToSendValues } from '@global-apis/companies';
import Project from '@global-interfaces/Project';
import { TypedDocumentNode, gql } from '@apollo/client';

import { graphqlClient } from '@global-apis/config';

interface MenuProject {
  id: number;
  active: boolean;
  name: string;
  children: { id: number; name: string }[];
}

interface MenuProjectValueData {
  projects: MenuProject[];
}

interface ProjectValueData {
  projects: Project[];
}

interface ProjectValueVariables {
  sort_by: {
    field: string;
    order: string;
  };
  filters: { active: boolean | null; only_parent?: boolean };
}

const PROJECTS_QUERY: TypedDocumentNode<ProjectValueData, ProjectValueVariables> = gql`
  query GetProjects($sort_by: SortBy, $filters: CompaniesProjectFilters) {
    projects(sort_by: $sort_by, filters: $filters) {
      id
      active
      address {
        address_1
        address_2
        id
        phone
        state
        zip_code
      }
      address_id
      billing_due_date
      billing_frequency
      budget_id
      expense_account
      external_id
      last_purchase_order_number
      name
      owner
      project_permissions {
        id
        is_reviewer
        project_id
        user_id
      }
      sage_available
      sage_id
      tax_rate
      users
    }
  }
`;

const PROJECTS_MENU_QUERY: TypedDocumentNode<MenuProjectValueData, ProjectValueVariables> = gql`
  query GetMenuProjects($sort_by: SortBy, $filters: CompaniesProjectFilters) {
    projects(sort_by: $sort_by, filters: $filters) {
      id
      active
      name
      children {
        id
        name
      }
    }
  }
`;

export const useProjectCreate = (options = {}) =>
  useMutation({
    mutationFn: (data: { name: string; company_id: number; users?: number[] }) => companiesAPI.project.create(data),
    ...options
  });

export const useProjectFetch = (id: number, options = {}) =>
  useQuery({
    queryKey: ['project', id],
    queryFn: () => companiesAPI.project.fetch(id).then((res) => res.data),
    ...options
  });

export const fetchProject = (queryClient, projectId) =>
  queryClient.fetchQuery({
    queryKey: ['project', projectId],
    queryFn: () => companiesAPI.project.fetch(projectId),
    staleTime: 1000 * 60 * 20
  });

export const useProjectUpdate = (options = {}) =>
  useMutation({
    mutationFn: ({ id, data }: { id: number; data: ProjectToSendValues }) => companiesAPI.project.update(id, data),
    ...options
  });

export const useMenuProjectList = (options = {}) =>
  useQuery<MenuProject[], Error>({
    queryKey: ['projects-only-active-parent'],
    queryFn: async () => {
      const { data } = await graphqlClient.query({
        query: PROJECTS_MENU_QUERY,
        fetchPolicy: 'network-only',
        variables: { sort_by: { field: 'name', order: 'asc' }, filters: { active: true, only_parent: true } }
      });

      return data.projects;
    },
    ...options
  });

export const useActiveProjectList = (options = {}) =>
  useQuery<Project[], Error>({
    queryKey: ['projects-only-active'],
    queryFn: async () => {
      const { data } = await graphqlClient.query({
        query: PROJECTS_QUERY,
        fetchPolicy: 'network-only',
        variables: { sort_by: { field: 'name', order: 'asc' }, filters: { active: true } }
      });

      return data.projects;
    },
    ...options
  });

export const useAllProjectList = (options = {}) =>
  useQuery<Project[], Error>({
    queryKey: ['projects-all'],
    queryFn: async () => {
      const { data } = await graphqlClient.query({
        query: PROJECTS_QUERY,
        fetchPolicy: 'network-only',
        variables: { sort_by: { field: 'name', order: 'asc' }, filters: { active: null } }
      });

      return data.projects;
    },
    ...options
  });

export const invalidateActiveProjectList = (queryClient) =>
  queryClient.invalidateQueries({ queryKey: ['projects', 'only-active'] });

export const invalidateAllProjectList = (queryClient) =>
  queryClient.invalidateQueries({ queryKey: ['projects', 'all'] });

export const setProjectList = (queryClient, newData) => queryClient.setQueryData(['projects'], newData);
