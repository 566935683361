import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import jwtDecode from 'jwt-decode';
import axios from 'axios';

export const config = {
  host: import.meta.env.VITE_BACKEND_HOST,
  graphqlHost: import.meta.env.VITE_DATA_NEXUS_HOST,
  agaveHost: import.meta.env.VITE_AGAVE_HOST
};

export interface PaginatedResponse<T> {
  count: number;
  next: string | null;
  previous: string | null;
  next_url: string | null;
  previous_url: string | null;
  results: T[];
}

const invalidateSession = () => {
  if (window.location.pathname !== '/login') {
    localStorage.removeItem('__token');
    localStorage.removeItem('__refresh-token');
    if (process.env.NODE_ENV !== 'test') {
      window.location.href = '/login';
    }
  }
};

const api = axios.create({
  baseURL: config.host,
  headers: { 'Content-Type': 'application/json' }
});

export const agaveAPI = axios.create({
  baseURL: config.agaveHost,
  headers: { 'Content-Type': 'application/json' }
});

const httpLink = createHttpLink({
  uri: config.graphqlHost
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err?.extensions?.code) {
        case 'UNAUTHENTICATED':
          invalidateSession();
      }
    }
  }

  if (networkError) {
    // biome-ignore lint: necessary console.log
    console.log(`[Network error]: ${networkError}`);
  }
});

const checkCompanyId = (token: string | null) => {
  if (token) {
    const decoded = jwtDecode<{ company_id?: number }>(token);
    const cachedCompanyId = localStorage.getItem('__company');

    if (!decoded?.company_id || decoded.company_id !== Number(cachedCompanyId)) {
      // This is necessary because a Super Admin can switch companies on app.inbuild, updating the token correctly.
      // However, test-1.inbuild retain the old token with the previous company_id,
      // so we need to invalidate the session in such cases to prevent mismatched company data.
      invalidateSession();
    }
  }
};

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('__token');

  checkCompanyId(token);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

export const graphqlClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache()
});

agaveAPI.interceptors.request.use(function (config) {
  const token = localStorage.getItem('__token');

  config.headers.Authorization = token ? `Bearer ${token}` : '';

  return config;
});

api.interceptors.request.use(function (config) {
  const token = localStorage.getItem('__token');

  config.headers.Authorization = token ? `Bearer ${token}` : '';

  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      invalidateSession();
    }
    return Promise.reject(error);
  }
);

export default api;

export const unauthenticatedApi = axios.create({
  baseURL: config.host,
  headers: { 'Content-Type': 'application/json' }
});
