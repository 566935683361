import { useContext, createContext, useEffect, useState, ReactNode } from 'react';

import { useAuth } from '@global-contexts/auth';
import SyncWizardModal from '@global-components/SyncWizardModal';

interface UserInteractionsContextProps {
  setIsIntegrationsModalOpen: (open: boolean) => void;
}

export const UserInteractionsContext = createContext<UserInteractionsContextProps>({} as UserInteractionsContextProps);

//this context should be used for all non-specific interactions with users
//for instance: onboarding tips/steps, rewards for users debuts on features and more
const UserInteractionsProvider = ({ children }: { children: ReactNode }) => {
  const [isIntegrationsModalOpen, setIsIntegrationsModalOpen] = useState(false);
  const { company, user } = useAuth();
  const { company_flags } = company;

  const noSetupDone = !company_flags?.integrations_setup?.some((i) => i.status === 'synced');

  useEffect(() => {
    if (
      user &&
      user?.isAdmin &&
      company.enable_import_options &&
      !company_flags?.integration_setup_refusal &&
      noSetupDone
    ) {
      setIsIntegrationsModalOpen(true);
    }
  }, [user, company_flags, company.enable_import_options, noSetupDone]);

  const onCloseIntegrationsModal = () => {
    setIsIntegrationsModalOpen(false);
  };

  return (
    <UserInteractionsContext.Provider
      value={{
        setIsIntegrationsModalOpen
      }}>
      {children}
      {company_flags && <SyncWizardModal isOpen={isIntegrationsModalOpen} onClose={onCloseIntegrationsModal} />}
    </UserInteractionsContext.Provider>
  );
};

export const useUserInteractions = () => useContext(UserInteractionsContext);

export default UserInteractionsProvider;
