import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';

import { useAuth } from '@global-contexts/auth';
import { useTagList } from '@global-hooks/tags';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';

interface Tag {
  id: number;
  color: string;
  name: string;
  default: boolean;
}

interface TagContextType {
  isLoading: boolean;
  fetchTags: (options?: RefetchOptions) => Promise<QueryObserverResult<Tag[], Error>>;

  tags: Tag[] | undefined;
}

export const TagContext = createContext<TagContextType>({} as TagContextType);

const TagProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { user, isAuthenticated } = useAuth();

  const [enabled, setEnabled] = useState(false);

  const { data: tags, refetch: fetchTags, isLoading } = useTagList({ staleTime: 1000 * 60 * 20, enabled });

  // biome-ignore lint/correctness/useExhaustiveDependencies: safe to ignore
  useEffect(() => {
    if (isAuthenticated() && user) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  }, [user]);

  return (
    <TagContext.Provider
      value={{
        isLoading,
        fetchTags,

        tags
      }}>
      {children}
    </TagContext.Provider>
  );
};

export const useTags = () => useContext(TagContext);

export default TagProvider;
