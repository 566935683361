import { FC, ReactNode, useState, useEffect, Suspense } from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { CircularProgress, Stack } from '@mui/material';
import { useAuth } from '@global-contexts/auth';

interface LaunchDarklyProviderProps {
  children: ReactNode;
}

interface LDFlags {
  projectChildren: boolean;
  mercoaEnabled: boolean;
}

const flags: LDFlags = {
  projectChildren: false,
  mercoaEnabled: false
};

export const LaunchDarklyProvider: FC<LaunchDarklyProviderProps> = ({ children }) => {
  const [LDProviderComponent, setLDProviderComponent] = useState<
    ({ children }: { children: ReactNode }) => JSX.Element | null
  >(() => null);
  const { user } = useAuth();

  useEffect(() => {
    asyncWithLDProvider({
      clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID,
      context: {
        kind: 'user',
        key: user?.email || 'anonymous',
        name: user?.full_name || 'Anonymous',
        email: user?.email || '',
        company_id: user?.profile.company?.id.toString(),
        company_name: user?.profile.company?.name
      },
      options: {
        bootstrap: flags
      }
    }).then((LDProvider) => {
      setLDProviderComponent(() => LDProvider);
    });
  }, [user]);

  if (!LDProviderComponent) {
    return (
      <Suspense
        fallback={
          <Stack alignItems="center" justifyContent="center" height="100vh" data-testid="launch-darkly-loading-spinner">
            <CircularProgress />
          </Stack>
        }>
        {children}
      </Suspense>
    );
  }

  return <LDProviderComponent>{children}</LDProviderComponent>;
};
