import { SwishjamProvider as ExternalSwishjamProvider } from '@swishjam/react';
import PropTypes from 'prop-types';

import { useAuth } from '@global-contexts/auth';

import { ALLOWED_THIRD_APP } from '@root/constants';

const SwishjamProvider = ({ children }) => {
  const { started, loading, generalSettings } = useAuth();

  if (started && !loading) {
    if (generalSettings?.swishjam_enabled) {
      return (
        <ExternalSwishjamProvider apiKey="swishjam_prdct-a80b4fcec1904f2d" disabled={!ALLOWED_THIRD_APP}>
          {children}
        </ExternalSwishjamProvider>
      );
    } else {
      // biome-ignore format:  no break line
      // biome-ignore lint: necessary console.log
      console.log(`%cSwishjam is disabled by the swishjam_enabled flag in the inBuild internal service`, `color: #a0f774; font-weight: bold;`);

      return <>{children}</>;
    }
  }

  return <>{children}</>;
};

SwishjamProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default SwishjamProvider;
