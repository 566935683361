import { useCallback, useEffect, useMemo, useState } from 'react';
import Step from '@mui/material/Step';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import SyncIcon from '@mui/icons-material/Sync';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Button from '@mui/material/Button';

import {
  ERPS_ENABLE_WEBSOCKET,
  PENDING,
  QUICKBOOKS,
  QUICKBOOKS_DESKTOP,
  STATUS_DONE,
  STATUS_FAILED,
  STATUS_PROCESSING
} from '@global-utils/defaultValues';
import quickBooksIcon from '@global-assets/images/erp/quickBooks.png';
import procoreIcon from '@global-assets/images/erp/procore.png';
import sageIcon from '@global-assets/images/erp/sage.png';
import ERPRow from '@global-components/SyncWizardModal/components/ERPRow';
import api from '@global-apis/config';
import agaveAPI from '@global-apis/agave';
import invoiceAPI from '@global-apis/invoice';
import { useToast } from '@global-contexts/toast';
import { useAuth } from '@global-contexts/auth';
import { ERPs } from '@global-interfaces/enums';
import { connectAgaveConnector, downloadAgaveScript } from '@global-utils/agave';

export enum Entities {
  PAYMENT_METHOD = 'paymentMethod',
  COST_CODES = 'costCode',
  PROJECTS = 'project',
  VENDORS = 'vendor'
}

interface ImportNumberProps {
  [Entities.PAYMENT_METHOD]: {
    already_imported: number;
    imported: number;
    status: null | boolean;
  };
  [Entities.COST_CODES]: {
    already_imported: number;
    imported: number;
    status: null | boolean;
  };
  [Entities.PROJECTS]: {
    already_imported: number;
    imported: number;
    status: null | boolean;
  };
  [Entities.VENDORS]: {
    already_imported: number;
    imported: number;
    status: null | boolean;
  };
}

interface StepStatusProps {
  [key: number]: string;
  0: string;
  1: string;
  2: string;
  3: string;
}

//update to comprise duplicates and errors
const importNumberInitialState: ImportNumberProps = {
  [Entities.PAYMENT_METHOD]: {
    already_imported: 0,
    imported: 0,
    status: null
  },
  [Entities.COST_CODES]: {
    already_imported: 0,
    imported: 0,
    status: null
  },
  [Entities.PROJECTS]: {
    already_imported: 0,
    imported: 0,
    status: null
  },
  [Entities.VENDORS]: {
    already_imported: 0,
    imported: 0,
    status: null
  }
};

const SetupGuideMessage = () => {
  return (
    <span>
      Follow the setup guide to get started by <a className="text-info cursor pointer">clicking here</a>.
    </span>
  );
};

const StepStatusIcon = ({ status }: { status: string }) => {
  switch (status) {
    case STATUS_PROCESSING:
      return <SyncIcon color="primary" />;
    case STATUS_DONE:
      return <CheckCircleIcon color="primary" />;
    case STATUS_FAILED:
      return <CancelRoundedIcon color="error" />;
    default:
      return <PanoramaFishEyeIcon sx={{ color: 'secondary.main' }} />;
  }
};

const SyncStepper = ({
  erp,
  setFinalStep,
  setIsSyncing
}: {
  erp: ERPs;
  setFinalStep: () => void;
  setIsSyncing: (state: boolean) => void;
}) => {
  const { toast } = useToast();
  const { company, flags } = useAuth();
  const [activeStep, setActiveStep] = useState(erp === ERPs.PROCORE ? 2 : 1);
  const [stepStatus, setStepStatus] = useState<StepStatusProps>({
    0: PENDING,
    1: PENDING,
    2: PENDING,
    3: PENDING
  });
  const [importNumber, setImportImportStatus] = useState<ImportNumberProps>(importNumberInitialState);
  const [downloaded, setDownloaded] = useState(false);

  const actionButtonText = useMemo(() => {
    if (erp === ERPs.QBD || (erp === ERPs.SAGE && flags.agaveConnector)) {
      return !downloaded ? 'Download' : 'Start';
    }
    return 'Connect';
  }, [downloaded, erp, flags.agaveConnector]);

  const isSage = erp === ERPs.SAGE;

  const downloadAgave = () => {
    if (!flags.agaveConnector) {
      return;
    }
    return downloadAgaveScript();
  };

  useEffect(() => {
    if (isSage) {
      const script = document.createElement('script');

      script.src = 'https://app.agaveapi.com/init.js';

      document.body.appendChild(script);
    }
  }, [isSage]);

  const exportWebSocket = useMemo(() => {
    const token = window.localStorage.getItem('__token');
    return new WebSocket(import.meta.env.VITE_WS_SERVER + '?token=' + token);
  }, []);

  const onWSOpen = useCallback(() => {
    exportWebSocket.send('{"channel": "frontend"}');
  }, [exportWebSocket]);

  const updateImportStatus = (message: {
    entity: Entities;
    already_imported: number;
    imported: number;
    success: null | boolean;
  }) => {
    const current = importNumber[message.entity];
    setImportImportStatus((prev) => ({
      ...prev,
      [message.entity]: {
        already_imported: current.already_imported + (message?.already_imported || 0),
        imported: current.imported + (message?.imported || 0),
        status: message?.success
      }
    }));
  };

  const shouldRedirect = () => {
    let redirect = false;

    const paymentStatus = importNumber[Entities.PAYMENT_METHOD].status;
    const costCodeStatus = importNumber[Entities.COST_CODES].status;
    const projectStatus = importNumber[Entities.PROJECTS].status;
    const vendorStatus = importNumber[Entities.VENDORS].status;

    // only PROCORE has no payment method
    if (erp === ERPs.PROCORE) {
      redirect = Boolean(costCodeStatus && projectStatus && vendorStatus);
    } else {
      redirect = Boolean(paymentStatus && costCodeStatus && projectStatus && vendorStatus);
    }

    return redirect;
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const onWSMessage = useCallback(
    (event: MessageEvent<string>) => {
      interface ReceivedObject {
        message: {
          already_imported: number;
          imported: number;
          entity: Entities;
          sender: string;
          message: string;
          success: boolean;
        };
      }
      const response: {
        Received?: ReceivedObject | string;
        user?: number;
      } = JSON.parse(event?.data);

      if (response?.Received === 'Connected') {
        setActiveStep(1);
        return;
      }

      const received = response?.Received as ReceivedObject;

      const entity = received?.message?.entity;
      const sender = received?.message?.sender;
      const message = received?.message?.message;

      if (entity === Entities.PAYMENT_METHOD && sender === `ImportPaymentMethods${erp}`) {
        updateImportStatus(received?.message);
        setActiveStep(2);
        if (!received?.message?.success) {
          toast(`Error syncing Accounts: ${message}`, { type: 'error', clearPrevious: false });
          return setStepStatus((prev) => ({ ...prev, 0: STATUS_FAILED, 1: STATUS_PROCESSING }));
        }
        setStepStatus((prev) => ({ ...prev, 0: STATUS_DONE, 1: STATUS_PROCESSING }));
      } else if (entity === Entities.COST_CODES && sender === `ImportCostCodes${erp}`) {
        updateImportStatus(received?.message);
        setActiveStep(3);
        if (!received?.message?.success) {
          toast(`Error syncing Cost Codes: ${message}`, { type: 'error', clearPrevious: false });
          return setStepStatus((prev) => ({ ...prev, 1: STATUS_FAILED, 2: STATUS_PROCESSING }));
        }
        setStepStatus((prev) => ({ ...prev, 1: STATUS_DONE, 2: STATUS_PROCESSING }));
      } else if (entity === Entities.PROJECTS && sender === `ImportProjects${erp}`) {
        updateImportStatus(received?.message);
        setActiveStep(4);
        if (!received?.message?.success) {
          toast(`Error syncing Projects: ${message}`, { type: 'error', clearPrevious: false });
          return setStepStatus((prev) => ({ ...prev, 2: STATUS_FAILED, 3: STATUS_PROCESSING }));
        }
        setStepStatus((prev) => ({ ...prev, 2: STATUS_DONE, 3: STATUS_PROCESSING }));
      } else if (entity === Entities.VENDORS && sender === `ImportVendors${erp}`) {
        setIsSyncing(false);
        updateImportStatus(received?.message);
        if (!received?.message?.success) {
          toast(`Error syncing Vendors: ${message}`, { type: 'error', clearPrevious: false });
          return setStepStatus((prev) => ({ ...prev, 3: STATUS_FAILED }));
        } else {
          // TODO: here is not the best way to handle this, because we are trusting that the Entities.VENDORS is the last one
          if (shouldRedirect()) {
            setTimeout(() => {
              setFinalStep();
            }, 3000);
          }
        }
        setStepStatus((prev) => ({ ...prev, 3: STATUS_DONE }));
      }
    },
    [erp]
  );

  const [handleFetchStatus, setHandleFetchStatus] = useState(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (ERPS_ENABLE_WEBSOCKET.includes(erp) && handleFetchStatus) {
      exportWebSocket.addEventListener('open', onWSOpen);
    }

    return () => {
      exportWebSocket?.removeEventListener?.('open', onWSOpen);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [erp, activeStep, stepStatus, handleFetchStatus]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: safe to ignore
  useEffect(() => {
    if (exportWebSocket && handleFetchStatus) {
      exportWebSocket.addEventListener('message', onWSMessage);
    }
    return () => {
      exportWebSocket?.removeEventListener?.('message', onWSMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportWebSocket, handleFetchStatus]);

  const importAccounts = async () => {
    if (erps[erp].endpoints.syncPaymentsAccounts !== '') {
      return api
        .get(erps[erp].endpoints.syncPaymentsAccounts)
        .then(async (results) => {
          if (results.data?.success) {
            setStepStatus((prev) => ({ ...prev, 0: STATUS_PROCESSING }));
          }
          if (!results.data?.success) {
            setIsSyncing(false);
            toast(results.data.Message || 'Error syncing Accounts', { type: 'error', clearPrevious: false });
            setStepStatus((prev) => ({ ...prev, 0: STATUS_FAILED }));
          }
        })
        .catch((err) => {
          setStepStatus((prev) => ({ ...prev, 0: STATUS_FAILED }));
          toast(err.response.data.Message || 'Error syncing Accounts', { type: 'error', clearPrevious: false });
          setIsSyncing(false);
        });
    }
  };

  const importCostCodes = async () => {
    if (erps[erp].endpoints.importCostCodes !== '') {
      return api
        .get(erps[erp].endpoints.importCostCodes)
        .then(async (results) => {
          if (results.data?.success) {
            setStepStatus((prev) => ({ ...prev, 1: STATUS_PROCESSING }));
          }
          if (!results.data?.success) {
            setIsSyncing(false);
            toast(results.data.Message || 'Error syncing Cost Codes', { type: 'error', clearPrevious: false });
            setStepStatus((prev) => ({ ...prev, 1: STATUS_FAILED }));
          }
        })
        .catch((err) => {
          toast(err.response.data.Message || 'Error syncing Cost Codes', { type: 'error', clearPrevious: false });
          setStepStatus((prev) => ({ ...prev, 1: STATUS_FAILED }));
          setIsSyncing(false);
        });
    }
  };

  const importProjects = async () => {
    if (erps[erp].endpoints.importProjects !== '') {
      return api
        .get(erps[erp].endpoints.importProjects)
        .then(async (results) => {
          if (results.data?.success) {
            setStepStatus((prev) => ({ ...prev, 2: STATUS_PROCESSING }));
          }
          if (!results.data?.success) {
            setIsSyncing(false);
            toast(results.data.Message || 'Error syncing Projects', { type: 'error', clearPrevious: false });
            setStepStatus((prev) => ({ ...prev, 2: STATUS_FAILED }));
          }
        })
        .catch((err) => {
          toast(err.response.data.Message || 'Error syncing Projects', { type: 'error', clearPrevious: false });
          setStepStatus((prev) => ({ ...prev, 2: STATUS_FAILED }));
          setIsSyncing(false);
        });
    }
  };

  const importVendors = async () => {
    if (erps[erp].endpoints.importVendors !== '') {
      return api
        .get(erps[erp].endpoints.importVendors)
        .then(async (results) => {
          if (results.data?.success) {
            setStepStatus((prev) => ({ ...prev, 3: STATUS_PROCESSING }));
          }
          if (!results.data?.success) {
            setIsSyncing(false);
            toast(results.data.Message || 'Error syncing Vendors', { type: 'error' });
            setStepStatus((prev) => ({ ...prev, 3: STATUS_FAILED }));
          }
        })
        .catch((err) => {
          toast(err.response.data.Message || 'Error syncing Vendors', { type: 'error' });
          setStepStatus((prev) => ({ ...prev, 3: STATUS_FAILED }));
          setIsSyncing(false);
        });
    }
  };

  const handleSageConnect = async () => {
    if (!flags.agaveConnector) {
      return importAccounts();
    }

    if (!company.id) {
      return;
    }

    await connectAgaveConnector(company.id).then(() => {
      fetchStatus();
    });
  };

  const importAllFromAgave = async () => {
    setIsSyncing(true);
    setStepStatus((prev) => ({
      ...prev,
      0: STATUS_PROCESSING,
      1: STATUS_PROCESSING,
      2: STATUS_PROCESSING,
      3: STATUS_PROCESSING
    }));

    await agaveAPI.import
      .paymentMethods()
      .then((res) => {
        if (res.data?.success) {
          setStepStatus((prev) => ({ ...prev, 0: STATUS_DONE }));
          setImportImportStatus((prev) => ({
            ...prev,
            [Entities.PAYMENT_METHOD]: {
              already_imported: res.data?.status?.imported || 0,
              imported: res.data?.status?.imported || 0,
              status: res.data?.success
            }
          }));
        }
        if (!res.data?.success) {
          setStepStatus((prev) => ({ ...prev, 0: STATUS_FAILED }));
        }
      })
      .catch(() => {
        setStepStatus((prev) => ({ ...prev, 0: STATUS_FAILED }));
      });

    await agaveAPI.import
      .costCodes()
      .then((res) => {
        if (res.data?.success) {
          setStepStatus((prev) => ({ ...prev, 1: STATUS_DONE }));
          setImportImportStatus((prev) => ({
            ...prev,
            [Entities.COST_CODES]: {
              already_imported: res.data?.status?.imported || 0,
              imported: res.data?.status?.imported || 0,
              status: res.data?.success
            }
          }));
        }
        if (!res.data?.success) {
          setStepStatus((prev) => ({ ...prev, 1: STATUS_FAILED }));
        }
      })
      .catch(() => {
        setStepStatus((prev) => ({ ...prev, 1: STATUS_FAILED }));
      });

    await agaveAPI.import
      .projects()
      .then((res) => {
        if (res.data?.success) {
          setStepStatus((prev) => ({ ...prev, 2: STATUS_DONE }));
        }
        if (!res.data?.success) {
          setStepStatus((prev) => ({ ...prev, 2: STATUS_FAILED }));
        }
        setImportImportStatus((prev) => ({
          ...prev,
          [Entities.PROJECTS]: {
            already_imported: res.data?.status?.imported || 0,
            imported: res.data?.status?.imported || 0,
            status: res.data?.success
          }
        }));
      })
      .catch(() => {
        setImportImportStatus((prev) => ({
          ...prev,
          [Entities.PROJECTS]: {
            already_imported: 0,
            imported: 0,
            status: false
          }
        }));
        setStepStatus((prev) => ({ ...prev, 2: STATUS_FAILED }));
      });

    await agaveAPI.import
      .vendors()
      .then((res) => {
        if (res.data?.success) {
          setStepStatus((prev) => ({ ...prev, 3: STATUS_DONE }));
          setImportImportStatus((prev) => ({
            ...prev,
            [Entities.VENDORS]: {
              already_imported: res.data?.status?.imported || 0,
              imported: res.data?.status?.imported || 0,
              status: res.data?.success
            }
          }));
        }
        if (!res.data?.success) {
          setStepStatus((prev) => ({ ...prev, 3: STATUS_FAILED }));
        }
      })
      .catch(() => {
        setStepStatus((prev) => ({ ...prev, 3: STATUS_FAILED }));
      });

    setIsSyncing(false);
  };

  const fetchStatus = async () => {
    if (erp === ERPs.SAGE && flags.agaveConnector) {
      return importAllFromAgave();
    }
    //for QBD we have to call all imports at once so the webconnector won't close connection and deactivate the msgs
    if (erp === ERPs.QBD) {
      if (activeStep === 1) {
        setIsSyncing(true);
        importAccounts();
        importCostCodes();
        importProjects();
        return importVendors();
      }
      return null;
    }
    switch (activeStep) {
      case 1:
        setIsSyncing(true);
        return importAccounts();
      case 2:
        return importCostCodes();
      case 3:
        return importProjects();
      case 4:
        return importVendors();
      default:
        return;
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: safe to ignore
  useEffect(() => {
    if (!isSage && handleFetchStatus) {
      fetchStatus();
    }
  }, [activeStep, handleFetchStatus]);

  const erps = {
    [ERPs.NONE]: {
      erp: ERPs.NONE,
      name: '',
      icon: '',
      text: '',
      connect: () => {},
      hide: [],
      download: () => {},
      endpoints: {
        syncPaymentsAccounts: '',
        importCostCodes: '',
        importProjects: '',
        importVendors: ''
      }
    },
    [ERPs.QB]: {
      erp: ERPs.QB,
      name: QUICKBOOKS,
      icon: quickBooksIcon,
      text: 'Connect to your QuickBooks Online account to get started.',
      connect: invoiceAPI.authQuickBooks,
      hide: [],
      download: () => {},
      endpoints: {
        syncPaymentsAccounts: 'quickbooks/import-payment-methods/',
        importCostCodes: 'quickbooks/import-cost-codes/',
        importProjects: 'quickbooks/import-projects/',
        importVendors: 'quickbooks/import-vendors/'
      }
    },
    [ERPs.QBD]: {
      erp: ERPs.QBD,
      name: QUICKBOOKS_DESKTOP,
      icon: quickBooksIcon,
      text: <SetupGuideMessage />,
      connect: importAccounts,
      hide: [],
      download: invoiceAPI.authQuickBooksDesktop,
      endpoints: {
        syncPaymentsAccounts: 'quickbooks-desktop/import-payment-methods/',
        importCostCodes: 'quickbooks-desktop/import-cost-codes/',
        importProjects: 'quickbooks-desktop/import-projects/',
        importVendors: 'quickbooks-desktop/import-vendors/'
      }
    },
    [ERPs.PROCORE]: {
      erp: ERPs.PROCORE,
      name: ERPs.PROCORE,
      icon: procoreIcon,
      text: 'Connect to your Procore account to get started.',
      connect: invoiceAPI.authProcore,
      hide: ['Accounts'],
      download: () => {},
      endpoints: {
        syncPaymentsAccounts: '',
        importCostCodes: 'procore/import-cost-codes/',
        importProjects: 'procore/import-projects/',
        importVendors: 'procore/import-vendors/'
      }
    },
    [ERPs.SAGE]: {
      erp: ERPs.SAGE,
      name: ERPs.SAGE,
      icon: sageIcon,
      text: <SetupGuideMessage />,
      connect: handleSageConnect,
      hide: [],
      download: downloadAgave,
      endpoints: {
        syncPaymentsAccounts: 'sage/import-payment-methods/',
        importCostCodes: 'sage/import-cost-codes/',
        importProjects: 'sage/import-projects/',
        importVendors: 'sage/import-vendors/'
      }
    }
  };

  const steps = [
    { id: Entities.PAYMENT_METHOD, label: 'Accounts' },
    { id: Entities.COST_CODES, label: 'Cost Codes' },
    { id: Entities.PROJECTS, label: 'Projects' },
    { id: Entities.VENDORS, label: 'Vendors' }
  ];

  const handleConnect = async () => {
    if (erp === ERPs.QBD || (erp === ERPs.SAGE && flags.agaveConnector)) {
      if (!downloaded) {
        setDownloaded(true);
        return erps[erp].download();
      }
      return erps[erp].connect();
    }
    return erps[erp].connect()?.then((res) => {
      if (!isSage) {
        window.open(res?.data.url, '_blank');
      }
    });
  };

  return (
    <>
      <ERPRow erp={erps[erp]} handleConnect={handleConnect} isConnect buttonText={actionButtonText}>
        <Button
          variant="outlined"
          onClick={() => {
            // it forces the fetch to start again
            if (handleFetchStatus) {
              setActiveStep(erp === ERPs.PROCORE ? 2 : 1);
              setHandleFetchStatus(false);
              setTimeout(() => {
                setHandleFetchStatus(true);
              }, 100);
            } else {
              setHandleFetchStatus(true);
            }
          }}>
          Sync Now
        </Button>
      </ERPRow>

      <Stack direction="column" alignItems="center" justifyContent="space-between" spacing={3}>
        <Stack direction="row" alignItems="space-around">
          <Stepper activeStep={activeStep} orientation="vertical" sx={{ width: '200px' }}>
            {steps.map(
              (step, i) =>
                !erps[erp].hide.some((s) => s === step.label) && (
                  <Step key={step.label}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <StepLabel StepIconComponent={() => <StepStatusIcon status={stepStatus[i]} />}>
                        {step.label}
                      </StepLabel>
                      <Typography
                        variant="body2"
                        color={
                          stepStatus[i] === STATUS_FAILED
                            ? 'error'
                            : stepStatus[i] === PENDING
                              ? 'secondary.main'
                              : 'primary'
                        }>
                        {importNumber[step.id].already_imported}
                      </Typography>
                    </Stack>
                  </Step>
                )
            )}
          </Stepper>
        </Stack>
        <Stack direction="row" gap={0.3}>
          <Typography>Need assistance?</Typography>
          <a target="_blank" rel="noreferrer" href="mailto:support@inbuild.ai">
            Click here
          </a>
        </Stack>
      </Stack>
    </>
  );
};

export default SyncStepper;
